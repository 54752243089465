var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left"},[_c('BaseHeader',{attrs:{"title":'Payments'}}),(_vm.loading)?_c('BaseLoading',{staticStyle:{"min-height":"400px","height":"90vh"}}):_c('section',{staticClass:"pt-4"},[_c('div',{staticClass:"card mt-2 border-0 bg-white shadow"},[_c('div',{staticClass:"card-body d-flex"},[_c('h2',[_vm._v("Payments")]),_c('v-spacer'),_c('v-text-field',{staticClass:"my-auto mb-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.$store.state.search),callback:function ($$v) {_vm.$set(_vm.$store.state, "search", $$v)},expression:"$store.state.search"}})],1),(_vm.userType != _vm.userTypes.writer)?_c('div',{staticClass:"card-body px-1 pt-1 pb-0"},[_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" Note: `Eligible` are writers whose unpaid balance is greater than 30$ ")]),_c('v-radio-group',{staticClass:"group-radio my-0",attrs:{"row":"","align":"center"},on:{"change":_vm.filterWriters},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":"All","value":"all"}}),_c('v-radio',{attrs:{"label":"Eligible","value":"active"}}),_c('v-radio',{attrs:{"label":"Ineligible","value":"inactive"}})],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.pendingPaymentWriters,"items":_vm.writer_list,"search":_vm.$store.state.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":item.status ? 'primary' : 'error',"small":""}},[_vm._v(" "+_vm._s(item.status ? "Eligible" : "Ineligible")+" ")])]}},{key:"item.names",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ViewWriterPending', params: { id: item.id } }}},[_vm._v(_vm._s(item.names || "--"))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.balance || "0.0")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"to":{ name: 'ViewWriterPending', params: { id: item.id } },"fab":"","elevation":"0","x-small":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)})],1):_c('div',{staticClass:"card-body px-1 pt-1 pb-0 py-4"},[_c('div',{staticClass:"m-2"},[_vm._v(" To view your pending payments and paid, click "),_c('router-link',{attrs:{"to":{ name: 'ViewWriterPending', params: { id: _vm.user.id } }}},[_vm._v("here.")]),_c('br'),_c('router-link',{staticClass:"btn btn-primary text-light mt-2",attrs:{"to":{ name: 'ViewWriterPending', params: { id: _vm.user.id } }}},[_vm._v("View my payments")])],1)])]),(_vm.selected.length > 0 && _vm.userType == _vm.userTypes.admin)?_c('div',{staticClass:"card border-0 bg-white shadow"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"text-uppercase"},[_vm._v("With Selected")]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.markAsPaid}},[_vm._v(" Mark as Paid ")])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }